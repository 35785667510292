import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autorefresh"
export default class extends Controller {
  static values = {
    src: String,
    conditionSrc: String,
    repeat: Boolean,
    interval: Number,
  }

  connect() {
    let intervalValue = 5000;
    if (this.hasIntervalValue) {
      intervalValue = this.intervalValue;
    }
    this.timer = setInterval(() => {
      if (this.hasConditionSrcValue) {
        fetch(this.conditionSrcValue, {
          headers: {
            "Accept": "application/json",
          }})
          .then(response => response.json())
          .then(data => {
            if (data.reload === true) {
              this.reload_frame();
            }
          });
      } else {
        this.reload_frame();
      }
    }, intervalValue);
  }

  reload_frame() {
    this.element.setAttribute('src', this.srcValue);
    if (!this.repeatValue) {
      this.disconnect();
    }
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
