//  ###################################################
//  #                                                 #
//  # Searches the given form HTML element for nested #
//  # elements having the class .b-supplemental-data  #
//  # and combines the data into a single JSON string #
//  # to the :supplemental_data attribute.            #
//  #                                                 #
//  ###################################################
document.addEventListener('turbo:load', () => {
  window.parseSupplementalData = function (form) {
    const $form = $(form);
    const $supplementalData = $form.find('.b-supplemental-data');
    if (!$supplementalData.length) { return; }

    const supplementalData = {};
    const $jsonInput = $('<input type="hidden"' +
      'name="personal_data_submission[supplemental_data]"' +
      'id="personal_data_submission_supplemental_data">');

    for (const field of Array.from($supplementalData)) {
      const $field = $(field);
      if ($field.is(':checkbox')) {
        supplementalData[$field.attr('name')] = $field.is(':checked');
      } else if ($field.is(':radio')) {
        if ($field.is(':checked')) {
          supplementalData[$field.attr('name')] = $field.val();
        }
      } else {
        supplementalData[$field.attr('name')] = $field.val();
      }
    }
    $jsonInput.val(JSON.stringify(supplementalData));
    $form.append($jsonInput);
    $form.find('.b-supplemental-data-group').remove();
  };

  const $forms = $('.personal-data-submission');
  for (const form of Array.from($forms)) {
    (function (pdsForm) {
      const $submitButton = $(pdsForm).find('input[type="submit"]');

      $submitButton.click(e => {
        console.log('Submit button clicked');
        e.preventDefault();
        parseSupplementalData(pdsForm);
        recaptchaSubmit(pdsForm);
      });
    })(form);
  }
});
